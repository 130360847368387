import React from "react";
import {
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
  IonText,
  IonRouterLink,
  IonGrid,
  isPlatform,
} from "@ionic/react";
import { logoFacebook, logoInstagram, logoPinterest } from "ionicons/icons";

const FooterRows: React.FC = () => {
  return (
    <React.Fragment>
      <IonRow
        style={{
          background: "var(--ion-color-dark)",
          borderBottom: "solid 1px white",
        }}
      >
        <IonCol
          size="12"
          className="ion-text-center ion-padding-top ion-padding-bottom"
          style={{ borderBottom: "solid 1px white" }}
        >
                    <IonButton
            href="https://www.instagram.com/twinpicsprod/"
            target="Instagram"
            fill="clear"
            color="light"
          >
            <IonIcon slot="icon-only" icon={logoInstagram} />
          </IonButton>
          <IonText color='light'>&nbsp;&nbsp;x&nbsp;&nbsp;</IonText>
          <IonButton
            href="https://www.instagram.com/lamafactory_com/"
            target="Instagram"
            fill="clear"
            color="light"
          >
            <IonIcon slot="icon-only" icon={logoInstagram} />
          </IonButton>
        </IonCol>
      </IonRow>
      <IonRow style={{ background: "var(--ion-color-dark)" }}>
        <IonCol
          size="12"
          size-md="6"
          className="ion-padding-start ion-text-left"
        >
          <IonText color="light">
            <p>© Powered by Lama Factory 2021, entre Lyon et Paris</p>
          </IonText>
        </IonCol>
        <IonCol
          size="12"
          size-md="6"
          className="ion-padding-end ion-text-right"
        >
          <IonText color="light">
            <IonRouterLink
              color="light"
              routerDirection="back"
              routerLink="/legal"
              style={{ display: "inline-block", paddingRight: "16px" }}
            >
              {" "}
              <p className="text-footer-link">Mentions légales</p>
            </IonRouterLink>
            <IonRouterLink
              color="light"
              routerDirection="back"
              routerLink="/trade"
              style={{ display: "inline-block" }}
            >
              {" "}
              <p className="text-footer-link">CGV</p>
            </IonRouterLink>
          </IonText>
        </IonCol>
      </IonRow>
    </React.Fragment>
  );
};

export default FooterRows;
