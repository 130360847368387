import React, { useState, useContext, useEffect } from "react";
import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonText,
  IonCard,
  isPlatform,
  IonFab,
  IonFabButton,
  IonButton,
  IonIcon,
} from "@ionic/react";
import FramePreview from "../../components/shared/FramePreview";
import DeleteLamAlert from "../../components/shared/DeleteLamAlert";
import fContext from "../../context/f-context";
import { useHttpClient } from "../../hooks/http-hook";
import LivingPreview from "../../components/shared/LivingPreview";
import { useHistory, useParams } from "react-router";
import PriceContext from "../../context/price-context";
import GallerySkeleton from "../../components/shop/GallerySkeleton";
import FooterRows from "../../components/shared/FooterRows";
import HidingHeader from "../../components/shared/HidingHeader";
import { useHidingHeader } from "../../hooks/hidding-header-hook";
import ProgressiveImage from "react-progressive-graceful-image";
import CustomShopToolbar from "../../components/shared/CustomShopToolbar";
import { arrowForwardOutline } from "ionicons/icons";

const Home: React.FC = () => {
  let selectedShop = useParams<{ shop: string }>().shop;
  const [showAlert, setShowAlert] = useState(false);
  const [loadedArticles, setLoadedArticles] = useState<any[]>([]);
  const [loadedShop, setLoadedShop] = useState<any>();
  const [selectedView, setSelectedView] = useState<string | undefined>("plain");
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [hideDecimal, setScrollYCurrent] = useHidingHeader(50);
  const fCtx = useContext(fContext);
  const priceCtx = useContext(PriceContext);
  const history = useHistory();

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "api/shop/shop/" + selectedShop
        );
        setLoadedArticles(responseData.articles);
        setLoadedShop(responseData.shop);
        //console.log(responseData.products);
      } catch (err) {}
    };
    fetchArticles();
  }, [sendRequest, selectedShop]);

  const cancelShowAlertHandler = () => {
    setShowAlert(false);
  };

  const deleteLamaHandler = () => {
    fCtx.deleteFrame();
    setShowAlert(false);
    history.push("/bonjour_lama");
  };

  var art_w_cm = fCtx.wSelected; //width art in cm
  var art_w = fCtx.imageWidth; //width art in px
  var art_h = fCtx.imageHeight; //height art in px

  var biseau_w_cm = 0.18; // biseau width cm

  var mode = fCtx.matStyle; //mode 'italiano' or 'standard'
  var mouldingWidth = 0; //moulding width in cm
  var mat = fCtx.matSize; //mat width in cm

  /*const div_list = loadedMouldings.map((moulding) =>
    getDivMulti(
      art_w,
      art_h,
      mode,
      mat,
      art_w_cm,
      Number(moulding.width),
      biseau_w_cm
    )
  );*/

  const articles = loadedArticles.map((article, index) => ({
    _id: article._id,
    name: article.name,
    price: article.price,
    art_path: article.imagePath,
    mat_color_selected: article.mat_color_selected,
    mat_shape_selected: article.mat_shape_selected,
    mat_mode_selected: article.mat_mode_selected,
    mat_size_selected: article.mat_size_selected,
    mount_selected: article.mount_selected,
    art_w_cm_selected: article.art_w_cm_selected,
    art_h_cm_selected: article.art_h_cm_selected,
    art_type: article.art_type,
    moulding_name: article.product_id.name,
    width_cm: article.product_id.width,
    style: article.product_id.style,
    tags: article.product_id.tags.split(";"),
    div: article.div,
    collection_moulding: article.product_id.collection_moulding,
  }));

  return (
    <React.Fragment>
      <DeleteLamAlert
        show={showAlert}
        onCancel={cancelShowAlertHandler}
        onDelete={deleteLamaHandler}
      />
      <IonPage>
        <HidingHeader hideDecimal={hideDecimal}>
          <CustomShopToolbar shop_name={selectedShop} />
        </HidingHeader>
        <IonContent
          scrollY={true}
          color="light"
          fullscreen={true}
          scrollEvents={true}
          onIonScroll={(e) => setScrollYCurrent(e.detail.scrollTop)}
        >
          <IonGrid className="ion-no-padding">
          <ProgressiveImage
              src={
                isPlatform("mobile")
                  ? "assets/img/KOFFEE SURF TRIP /cover_mobile.jpg"
                  : "assets/img/KOFFEE SURF TRIP /cover_desktop.jpg"
              }
              placeholder={
                isPlatform("mobile")
                  ? "assets/img/KOFFEE SURF TRIP /cover_mobile_tn.jpg"
                  : "assets/img/KOFFEE SURF TRIP /cover_desktop_tn.jpg"
              }
            >
              {(src: string | undefined, loading: any) => (
                <IonRow
                  className="custom-card-section ion-text-center"
                  style={{
                    backgroundImage: "url('" + src + "')",
                    filter: loading ? "blur(15px)" : "",
                  }}
                >
                  <IonCol
                    size="12"
                    offset="0"
                    size-md="4"
                    offset-md="7"
                    size-lg="4"
                    offset-lg="7"
                    className="text-center y-align"
                  >
                    <IonText color="light">
                      <h1
                        className="text-center-md-down text-right-lg-up"
                        style={{ marginBottom: "0px" }}
                      >
                        {"THE KØFFEE SURF TRIP"}
                      </h1>
                      <p className="shop-artist-name text-center-md-down text-right-lg-up">
                        <i>by Twin Pics</i>
                      </p>
                    </IonText>
                  </IonCol>
                  <IonCol
                    size="12"
                    offset="0"
                    size-md="4"
                    offset-md="7"
                    size-lg="4"
                    offset-lg="7"
                    className="text-center"
                  >
                    <IonButton
                      //className="xl-button"
                      color="dark"
                      fill="solid"
                      mode="ios"
                      shape="round"
                      disabled={false}
                      routerLink={"/shop/KOFFEE SURF TRIP /"}
                      routerDirection="forward"
                      //onClick={startDigitalPhoto}
                      style={{ width: "250px" }}
                      //onClick={startHeart}
                    >
                      <p className="text-button">
                      DÉCOUVRIR <IonIcon icon={arrowForwardOutline} />
                      </p>
                    </IonButton>
                  </IonCol>
                </IonRow>
              )}
            </ProgressiveImage>
            <FooterRows />
          </IonGrid>
        </IonContent>
      </IonPage>
    </React.Fragment>
  );
};
export default Home;
